import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock} from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions for Advanced or Recurrent Endometrial Carcinoma`,
    keywords: `keytruda adverse reactions for advanced or recurrent endometrial carcinoma`,
    description: `Learn about adverse reactions to KEYTRUDA® (pembrolizumab) in patients with advanced or recurrent endometrial carcinoma. See tables here.`,
    schemaJsonLD: [ 
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-or-recurrent-endometrial-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-or-recurrent-endometrial-carcinoma/","@type":"MedicalWebPage","name":"Selected Safety Profile in KEYNOTE⁠-⁠868","description":"The safety of KEYTRUDA in combination with chemotherapy (paclitaxel and carboplatin) was investigated in KEYNOTE⁠-⁠868, which included 759 dMMR and pMMR patients with advanced or recurrent endometrial carcinoma who received KEYTRUDA 200⁠ ⁠mg every 3 weeks and chemotherapy for 6 cycles followed by KEYTRUDA 400⁠ ⁠mg every 6 weeks for up to 14 cycles (n=382) or placebo and chemotherapy for 6 cycles followed by placebo for up to 14 cycles (n=377). The median duration of exposure to KEYTRUDA was 5.6 months (range: 1 day to 24.0 months)."}',
    ]
}

//from /src/util/data/indications.js
const indicationId = 1;
const jobCode = jobCodes[32].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Selected Safety Profile in KEYNOTE&#8288;-&#8288;868'},
]


const Page = ({ location }) => {

    return (
        // needs job code
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }> 
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label} bgColor='cloud'>
                            <TextBlock >
                                <p>
                                    The safety of KEYTRUDA in combination with chemotherapy (paclitaxel and carboplatin) was investigated in KEYNOTE&#8288;-&#8288;868, which included 759 dMMR and pMMR patients with advanced or recurrent endometrial carcinoma who received KEYTRUDA 200&#8288; &#8288;mg every 3 weeks and chemotherapy for 6 cycles followed by KEYTRUDA 400&#8288; &#8288;mg every 6 weeks for up to 14 cycles (n=382) or placebo and chemotherapy for 6 cycles followed by placebo for up to 14 cycles (n=377). The median duration of exposure to KEYTRUDA was 5.6 months (range: 1 day to 24.0 months).
                                </p>                                
                                <h4>Serious adverse reactions</h4>
                                <p>
                                    Serious adverse reactions occurred in 35% of patients receiving KEYTRUDA in combination with chemotherapy, compared to 19% of patients receiving placebo in combination with chemotherapy.
                                </p>
                                <h4>Fatal adverse reactions</h4>
                                <p>
                                    Fatal adverse reactions occurred in 1.6% of patients receiving KEYTRUDA in combination with chemotherapy, including COVID-19 (0.5%), and cardiac arrest (0.3%).
                                </p>
                                <h4>Discontinuation and dose reduction</h4>
                                <p>
                                    KEYTRUDA was discontinued for an adverse reaction in 14% of patients.  Chemotherapy dose reduction was required in 29% of patients receiving KEYTRUDA in combination with chemotherapy, compared to 23% of patients receiving placebo in combination with chemotherapy.  There were no clinically meaningful differences in chemotherapy discontinuations or interruptions between arms.
                                </p>
                                <p>
                                    Adverse reactions occurring in patients treated with KEYTRUDA and chemotherapy were generally similar to those observed with KEYTRUDA alone or chemotherapy alone, with the exception of rash (33% all Grades; 2.9% Grades 3–4).
                                </p>
                            </TextBlock>
                        </PageSection>
                

                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
